<template>
    <h2>Project Origin</h2>
    <h2>To give everyone an easy way to gift something that is DIY but with custom images, custom shapes, to make them feel more personal</h2>
</template>

<script>
export default {
    name: 'About',
}
</script>

<style></style>